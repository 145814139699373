.inactiveImage {
  filter: grayscale(100%);
  cursor: pointer;
}

.inactiveImage:hover {
  filter: grayscale(50%);
}

.activeImage {
  cursor: pointer;
}
