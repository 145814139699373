html,
body {
  height: 100%;
  background-color: rgb(252, 252, 252);
}

.navbar {
  background-color: #ffffff !important;
}

#root {
  height: 100%;
}

.cursor-hover:hover {
  cursor: pointer;
}

.table-responsive {
  overflow-x: visible !important;
  overflow-y: visible !important;
}

.link-dots:hover {
  color: rgb(200, 200, 200) !important;
}

.modal-header {
  border-bottom: 0 none;
}

.modal-footer {
  border-top: 0 none;
}
